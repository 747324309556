import React, {useEffect, useState} from "react";
import {Icon} from "@iconify/react";
// material
import {Backdrop, Box, Paper, Stack, Tooltip} from "@mui/material";
//
import Scrollbar from "../Scrollbar";
import {MIconButton} from "../@material-extend";
import SettingMode from "./SettingMode";
import useSettings from "../../../hooks/useSettings";
import {CloseRounded, DarkModeRounded, WbSunnyRounded} from "@mui/icons-material";

// ----------------------------------------------------------------------

const DRAWER_WIDTH = 260;

const Settings = () => {
    const [open, setOpen] = useState(false);
    const {themeMode} = useSettings();

    useEffect(() => {
        if (open) {
            document.body.style.overflow = "hidden";
        } else {
            document.body.style.overflow = "unset";
        }
    }, [open]);

    const handleToggle = () => {
        setOpen((prev) => !prev);
    };

    const handleClose = () => {
        setOpen(false);
    };

    return (
        <React.Fragment>
            <Backdrop
                sx={{zIndex: (theme) => theme.zIndex.drawer + 1}}
                open={open}
                onClick={handleClose}
            />

            <Box
                sx={{
                    bottom: 0,
                    right: 0,
                    position: "fixed",
                    zIndex: (theme) => theme.zIndex.drawer + 2,
                    ...(open && {right: 12})
                }}>
                <Box
                    sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        p: 1,
                        right: "24px",
                        bottom: "24px",
                        color: "grey.800",
                        position: "absolute",
                        bgcolor: "common.white",
                        borderRadius: "50%",
                        backdropFilter: "blur(6px)",
                        boxShadow: (theme) => theme.customShadows.z12
                    }}>
                    <Tooltip title="Mode">
                        <MIconButton
                            color="inherit"
                            onClick={handleToggle}
                            sx={{
                                p: 0,
                                width: 30,
                                height: 30,
                                color: (theme) => theme.palette.grey["600"],
                                transition: (theme) =>
                                    theme.transitions.create("all"),
                                "&:hover": {
                                    color: "primary.main",
                                    bgcolor: "transparent"
                                }
                            }}>
                            {open
                                ? <CloseRounded/>
                                : themeMode === "light"
                                    ? <WbSunnyRounded/> : <DarkModeRounded/>
                            }


                        </MIconButton>
                    </Tooltip>
                </Box>

                <Paper
                    sx={{
                        width: "0px",
                        overflow: "hidden",
                        height: "180px",
                        position: "absolute",
                        right: 10,
                        bottom: 120,
                        boxShadow: (theme) => theme.customShadows.z24,
                        transition: (theme) =>
                            theme.transitions.create("width"),
                        ...(open && {
                            width: DRAWER_WIDTH,
                            height: "180px",
                            position: "absolute",
                            right: 10,
                            bottom: 120
                        })
                    }}>
                    <Scrollbar sx={{height: 1}}>
                        <Stack spacing={4} sx={{pt: 1, px: 3, pb: 10}}>
                            <Stack spacing={1.5}>
                                <MIconButton
                                    onClick={handleClose}
                                    sx={{marginLeft: "80%"}}>
                                    <Icon
                                        icon="mdi:close-circle-multiple-outline"
                                        width={20}
                                        height={20}
                                    />
                                </MIconButton>
                                <SettingMode/>
                            </Stack>
                        </Stack>
                    </Scrollbar>
                </Paper>
            </Box>
        </React.Fragment>
    );
};
export default Settings;
