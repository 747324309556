import {useCallback, useEffect, useState} from "react";
import {useRequest} from "hooks/useRequest";

const useMoralisPrice = (chain, address, enabled = false) => {
    const [{handler}, loading] = useRequest();
    const [data, setData] = useState([]);

    const apikey = "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJub25jZSI6IjcxODI3ZDUxLTE3M2ItNDcyOC04NTBjLWZkMzFkNGQ5YjRhYiIsIm9yZ0lkIjoiMTcyODYyIiwidXNlcklkIjoiMTcyNTM0IiwidHlwZUlkIjoiNzA4ODYyYWYtNzVlOC00M2NjLTk2ZjUtNjBkM2YyMGE1ZDY2IiwidHlwZSI6IlBST0pFQ1QiLCJpYXQiOjE2ODI5NDE1MTYsImV4cCI6NDgzODcwMTUxNn0.7SnQLEGLVFdkoyw823wtc0zMv7k0iIR0X-XBXBypzGk"

    const fetchData = useCallback(() => {
        handler.get('https://deep-index.moralis.io/api/v2.2/erc20/:address/price', {
            params: {
                address, chain, include: "percent_change"
            }, headers: {'X-API-Key': apikey},
        }).then(({data}) => setData({
            ...data, name: data.tokenName, price: data.usdPrice, percentChange: data["24hrPercentChange"]
        })).catch((error) => console.log(error));
    }, [handler, chain, address]);

    useEffect(() => {
        if (enabled) {
            fetchData();
        }
    }, [fetchData, enabled]);

    return [data, loading];
}


export default useMoralisPrice;