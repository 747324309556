import React from "react";
import {Box, Container, Stack, Typography} from "@mui/material";
import {animateScroll as scroll} from "react-scroll";
import {useTheme} from "@mui/material/styles";

const Footer = () => {
    const toggleHome = () => {
        scroll.scrollToTop();
    };
    const theme = useTheme();
    return (
        <Box
            sx={{
                width: "100%",
                padding: "40px 0px",
                position: "relative",
                boxSizing: "border-box",
                overflow: "hidden",
                background: theme.palette.primary.main
            }}>
            <Container maxWidth="lg">
                <Stack alignItems="center" spacing={2}>
                    <Typography
                        variant="h3"
                        sx={{color: "#fff", cursor: "pointer"}}
                        onClick={toggleHome}>
                        Cryptogram
                    </Typography>
                    <Typography
                        variant="body1"
                        sx={{textAlign: "center", width: "45%", color: "#fff"}}>
                        Get more crypto for cash. Other exchanges charge high
                        fees to buy and sell crypto. We charge 0 to very little
                        fees. Sign up fast.
                    </Typography>

                    <Typography variant="body2" color="common.white">
                        Copyright @{new Date().getFullYear()} Cryptogram
                    </Typography>
                </Stack>
            </Container>
        </Box>
    );
};

export default Footer;
