import React from "react";
import {Drawer, List, ListItem, ListItemIcon, Box, Stack} from "@mui/material";
import {navItems} from "../header";
import {Link as ScrollLink} from "react-scroll";
import {Close} from "@mui/icons-material";
import {styled} from "@mui/material/styles";
import {ContainedButton, OutlinedButton} from "../components";

const Sidebar = ({open, toggle}) => {
    const drawerWidth = 400;

    return (
        <Drawer
            anchor="left"
            open={open}
            onClose={toggle}
            sx={{
                "& .MuiDrawer-paper": {
                    boxSizing: "border-box",
                    width: drawerWidth
                }
            }}>
            <Stack spacing={2} height={600} alignItems="center">
                <List sx={{width: "100%"}}>
                    <ListItem onClick={toggle} sx={{cursor: "pointer"}}>
                        <Box flexGrow={1}></Box>

                        <ListItemIcon>
                            <CloseIcon />
                        </ListItemIcon>
                    </ListItem>
                    <Box>
                        {navItems.map((sideItem) => (
                            <ListItem
                                key={sideItem.id}
                                sx={{flexDirection: "column"}}>
                                <ListItemScrollLink
                                    to={sideItem.idm}
                                    smooth={true}
                                    duration={500}
                                    spy={true}
                                    exact="true"
                                    offset={-80}
                                    onClick={toggle}>
                                    {sideItem.name}
                                </ListItemScrollLink>
                            </ListItem>
                        ))}

                        <ListItem sx={{flexDirection: "column"}}>
                            <ListItemLink href="https://www.facebook.com/OraCoinFoundation">Oracoin</ListItemLink>
                        </ListItem>
                    </Box>

                </List>

                <Box flexGrow={1}></Box>

                <Stack maxWidth={200} spacing={1}>
                    <PageLink href="/auth/register">
                        <SideOutlinedButton>Sign in</SideOutlinedButton>
                    </PageLink>

                    <PageLink href="/auth/login">
                        <SideContainedButton>Sign up</SideContainedButton>
                    </PageLink>
                </Stack>
            </Stack>


        </Drawer>
    );
};


const CloseIcon = styled(Close)(({theme}) => ({
    fontSize: "30px",
    color: theme.palette.primary.main
}));

const ListItemScrollLink = styled(ScrollLink)(({theme}) => ({
    fontFamily: theme.typography.fontFamily,
    position: "relative",
    margin: "10px 0px",
    padding: "10px 0px",
    fontWeight: 500,
    color: theme.palette.text.primary,
    cursor: "pointer",
    fontSize: "0.9rem",
    "&::after": {
        content: "''",
        height: 2,
        width: 0,
        position: "absolute",
        bottom: 0,
        left: 0,
        right: 0,
        margin: "auto",
        background: theme.palette.primary.main,
        transition: "all .3s ease-in-out"
    },
    "&:hover::after": {
        width: "100%",
        transition: "all .5s ease-in-out"
    }
}));

const ListItemLink = styled("a")(({theme}) => ({
    position: "relative",
    margin: "10px 0px",
    padding: "10px 0px",
    fontWeight: 500,
    textDecoration: "none",
    color: theme.palette.text.primary,
    cursor: "pointer",
    fontSize: "0.9rem",
    "&::after": {
        content: "''",
        height: 2,
        width: 0,
        position: "absolute",
        bottom: 0,
        left: 0,
        right: 0,
        margin: "auto",
        background: theme.palette.primary.main,
        transition: "width .3s ease-in-out"
    },
    "&:hover::after": {
        width: "100%",
        transition: "width .5s ease-in-out"
    },
    [theme.breakpoints.down("lg")]: {
        margin: "0px 10px"
    }
}));

const SideOutlinedButton = styled(OutlinedButton)(() => ({
    width: "100%",
    marginTop: 20
}));

const SideContainedButton = styled(ContainedButton)(() => ({
    width: "100%"
}));

const PageLink = styled("a")(() => ({
    textDecoration: "none",
    "&:not(:first-of-type)": {
        marginTop: 20
    }
}));

export default Sidebar;
