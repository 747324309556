import axios from "axios";
import {useState, useEffect} from "react";
import useMountHandler from "hooks/useMountHandler";

axios.defaults.headers.common["Content-Type"] = "application/json";
axios.defaults.headers.common["Accept"] = "application/json";
axios.defaults.headers.common["X-Requested-With"] = "XMLHttpRequest";

class HttpService {
    constructor(config) {
        this.handler = axios.create(config);
        this.canResetSignal = !config?.signal;
        this.resetSignal();
    }

    resetSignal = () => {
        if (this.canResetSignal) {
            this.controller = new AbortController();
            this.handler.defaults.signal = this.controller.signal;
        }
    };

    cancel = () => {
        this.controller?.abort();
    };
}

/**
 * Request hook
 *
 * @returns {[axios, boolean]}
 */
export const useRequest = (options) => {
    const [httpService] = useState(() => new HttpService(options?.config));
    const handler = useMountHandler();
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        const interceptors = httpService.handler.interceptors;

        const requestInterceptor = interceptors.request.use((config) => {
            handler.execute(() => setLoading(true));

            return config;
        });

        const responseInterceptor = interceptors.response.use(
            (response) => {
                handler.execute(() => setLoading(false));
                return response;
            },
            (error) => {
                handler.execute(() => setLoading(false));
                return Promise.reject(error);
            }
        );

        return () => {
            interceptors.request.eject(requestInterceptor);
            interceptors.response.eject(responseInterceptor);
        };
    }, [httpService, handler]);

    useEffect(() => {
        httpService.resetSignal();
        return () => httpService.cancel();
    }, [httpService]);

    return [httpService, loading];
};
