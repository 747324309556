import React from "react";
import {
    Body,
    CentreWrapper,
    Desc,
    Header,
    RootStyle,
    Subheader
} from "../components";
import {Box, Card, Container, Stack} from "@mui/material";
import {styled} from "@mui/material/styles";
import Flash from "./component/flash";
import Phone from "./component/phone";
import Secure from "./component/secure";
import {MotionInView, varFadeInDown, varFadeInUp} from "../animate";

const Features = () => {
    return (
        <RootStyle id="features" sx={{mt: {xs: "20px", md: "80px"}}}>
            <Container maxWidth="lg">
                <CentreWrapper>
                    <MotionInView variants={varFadeInDown}>
                        <CentreWrapper>
                            <Desc>Fastest Solution</Desc>
                            <Header>Accessible to anyone</Header>
                            <Subheader
                                sx={{
                                    width: {xs: "100%", md: "50%"},
                                    mb: "40px",
                                    textAlign: "center"
                                }}>
                                Lightning fast transactions. Buy and sell with
                                ease and peace of mind. Invest in cryptocurrency
                                today with just a few clicks.
                            </Subheader>
                        </CentreWrapper>
                    </MotionInView>
                    <MotionInView variants={varFadeInUp}>
                        <Stack
                            direction={{xs: "column", md: "row"}}
                            spacing={4}>
                            {cardItems.map((cardItem) => (
                                <CardWrapper
                                    cardItem={cardItem}
                                    key={cardItem.id}
                                />
                            ))}
                        </Stack>
                    </MotionInView>
                </CentreWrapper>
            </Container>
        </RootStyle>
    );
};
const CardWrapper = ({cardItem}) => {
    return (
        <FeatureCard isFirst={cardItem.id === 1} key={cardItem.id}>
            <LogoWrapper isFirst={cardItem.id === 1}>
                {cardItem.logo}
            </LogoWrapper>
            <FeatureHeader isFirst={cardItem.id === 1} sx={{mt: "40px"}}>
                {cardItem.header}
            </FeatureHeader>
            <Body>{cardItem.text}</Body>
        </FeatureCard>
    );
};

const cardItems = [
    {
        id: 1,
        logo: <Flash />,
        header: "Instant Trading",
        text: "Instant trading for Real-time wallets balance/coin updates and notification."
    },
    {
        id: 2,
        logo: <Phone />,
        header: "24/7 support",
        text: "Our support assistance staff is available 24 hours a day to help you for trading."
    },
    {
        id: 3,
        logo: <Secure />,
        header: "Secure storage",
        text: "Trade peace of mind is 2FA, Browser, Session and Device Management, and more"
    }
];

const FeatureCard = styled(({isFirst, ...otherProps}) => {
    return <Card {...otherProps} />;
})(({theme, isFirst}) => ({
    borderRadius: 50,
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    boxShadow: "none",
    border: `3px solid ${theme.palette.grey["100"]}`,
    padding: 50,
    paddingRight: 100,
    ...(isFirst && {
        background: theme.palette.grey["100"],
        color: theme.palette.grey["800"]
    }),
    [theme.breakpoints.down("lg")]: {
        paddingRight: 50
    },
    [theme.breakpoints.down("md")]: {
        paddingRight: 100
    },
    [theme.breakpoints.down("sm")]: {
        paddingRight: 50
    }
}));

const LogoWrapper = styled(({isFirst, ...otherProps}) => {
    return <Box {...otherProps} />;
})(({theme, isFirst}) => ({
    borderRadius: "50%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    padding: 10,
    background: theme.palette.grey["100"],
    ...(isFirst && {
        background: theme.palette.background.paper,
        boxShadow: theme.shadows[10]
    })
}));

const FeatureHeader = styled(({isFirst, ...otherProps}) => {
    return <Subheader {...otherProps} />;
})(({theme, isFirst}) => ({
    ...theme.typography.h5,
    fontWeight: 800,
    color: theme.palette.text.primary,
    ...(isFirst && {
        color: theme.palette.grey["800"]
    })
}));

export default Features;
